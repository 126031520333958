import axios from 'axios'
import vm from '../main'
const {config: {baseURL}} = vm.$store.state // 接口请求路径

const instance = axios.create({
  baseURL,
  timeout: 24 * 3600 * 1000
});

instance.interceptors.request.use(config => {
  const {token} = vm.$store.state;
  config.headers.common['Authorization'] = `Bearer ${token}`;
  return config;
});

instance.interceptors.response.use(res => res.data);

function http(method, path, params={}, config={}) {
  // vm.$Message.loading('正在加载中...');
  return new Promise((resolve, reject) => {
    const req = method === 'get' ? instance.get(path, {params}) : instance.post(path, params, config);
    req.then(res => _handleSuccess(res, resolve, reject))
    .catch(error => _handleError());
  });
};

function _handleSuccess(res, resolve, reject) {
  vm.$Message.destroy();
  switch(res.code) {
    case 0:
      resolve(res);
    break;
    case -100:
      vm.$router.push({name: 'Login', query: {code: 100}});
    break;
    case -101:
      vm.$router.push({name: 'Login', query: {code: 101}});
    break;
    default:
      vm.$Message.info(res.msg);
      return reject(res.msg);
  };
};

function _handleError() {
  vm.$Message.destroy();
  vm.$Message.error('服务器错误');
}

export const get = (...arg) => http('get', ...arg)
export const post = (...arg) => http('post', ...arg)